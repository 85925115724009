// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-me-js": () => import("./../../../src/pages/contact-me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-garnik-papik-js": () => import("./../../../src/pages/garnik-papik.js" /* webpackChunkName: "component---src-pages-garnik-papik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-language-of-silence-js": () => import("./../../../src/pages/language-of-silence.js" /* webpackChunkName: "component---src-pages-language-of-silence-js" */),
  "component---src-pages-museum-night-js": () => import("./../../../src/pages/museum-night.js" /* webpackChunkName: "component---src-pages-museum-night-js" */),
  "component---src-pages-rethinking-quarantine-js": () => import("./../../../src/pages/rethinking-quarantine.js" /* webpackChunkName: "component---src-pages-rethinking-quarantine-js" */)
}

